import React, { useEffect, useState, useRef } from "react";
import commonStyles from "../../components/css/common.module.css";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import Button from "../../components/button";
import { BackArrowSvg, NextArrowSvg } from "../../components/svgcomponents";
import { Helmet } from "react-helmet";

const OurStoryPage = () => {
  const { strings, modalOpen, focus, setFocus } = useAppData();
  const divref = useRef();
  const [indexNo, setIndexNo] = useState(1);
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  // const elRef = useRef();
  // useEffect(() => {
  //   const el = elRef.current;
  //   if (el) {
  //     const onWheel = (e) => {
  //       if (e.deltaY == 0) return;
  //       e.preventDefault();
  //       el.scrollTo({
  //         left: el.scrollLeft + e.deltaY,
  //         behavior: "smooth",
  //       });
  //     };
  //     el.addEventListener("wheel", onWheel);
  //     return () => el.removeEventListener("wheel", onWheel);
  //   }
  // }, []);
  const Data = [
    {
      id: 1,
      title: strings.ourStoryPage.itemHeader1,
      content1: strings.ourStoryPage.item1Content1,
      content2: strings.ourStoryPage.item1Content2,
    },
    {
      id: 2,
      title: strings.ourStoryPage.itemHeader2,
      content1: strings.ourStoryPage.item2Content1,
    },
    {
      id: 3,
      title: strings.ourStoryPage.itemHeader3,
      content1: strings.ourStoryPage.item3Content1,
      content2: strings.ourStoryPage.item3Content2,
    },
    {
      id: 4,
      title: strings.ourStoryPage.itemHeader4,
      content1: strings.ourStoryPage.item4Content1,
      boldContent: strings.ourStoryPage.item4Content2,
      content2: strings.ourStoryPage.item4Content3,
      content3: strings.ourStoryPage.item4Content4,
    },
    {
      id: 5,
      title: strings.ourStoryPage.itemHeader5,
      content1: strings.ourStoryPage.item5Content1,
      content2: strings.ourStoryPage.item5Content2,
    },
  ];

  const nextClick = () => {
    let slideBlogIndex = indexNo;
    if (indexNo < Data?.length) {
      slideBlogIndex = indexNo + 1;
      setIndexNo(indexNo + 1);
    }
    const ele = document.getElementById(`blog${slideBlogIndex}`);
    ele.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };
  const prevClick = () => {
    let slideBlogIndex = indexNo;
    if (indexNo > 1) {
      slideBlogIndex = indexNo - 1;
      setIndexNo(indexNo - 1);
      const ele = document.getElementById(`blog${slideBlogIndex}`);
      ele.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };
  const renderHeader = () => {
    return (
      <div className={styles.ourStoryPageInsideContainerStyle}>
        <div className={styles.headerContainerStyle}>
          <h1 className={styles.headerTextStyle}>
            {strings.ourStoryPage.headerText}
          </h1>
          <Button
            buttonName={strings.ourStoryPage.buttontext}
            btnStyle={styles.buttonStyle}
            onClick={() =>
              divref.current.scrollIntoView({ behavior: "smooth" })
            }
          />
        </div>
      </div>
    );
  };
  const renderSecondContainer = () => {
    return (
      <div ref={divref} className={styles.secondMainContainerStyle}>
        <div className={styles.secondInsideContainerStyle}>
          <div className={styles.secondContainerStyle}>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentHeaderTextStyle}>
                {strings.ourStoryPage.subHeaderText}
              </p>
              <p className={styles.contentParaTextStyle}>
                {strings.ourStoryPage.headerContentText}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderThirdContainer = () => {
    return (
      <div className={styles.thirdMainContainerStyle}>
        <div className={styles.thirdInsideContainerStyle}>
          <div className={styles.thirdContainerStyle}>
            <p className={styles.thirdTextStyle}>
              {strings.ourStoryPage.quoteText}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderFourthContainer = () => {
    return (
      <div className={styles.fourthMainContainerStyle}>
        <div className={styles.fourthInsideContainerStyle}>
          <div className={styles.fourthContainerStyle}>
            {Data.map((item, index) => {
              return (
                <div
                  id={"blog" + item.id}
                  key={index}
                  className={styles.cardWrapperStyle}
                >
                  {item.title && (
                    <p className={styles.contentHeaderTextStyle}>
                      {item.title}
                    </p>
                  )}
                  <div className={styles.contentTextwrapperStyle}>
                    {item.content1 && (
                      <p className={styles.contentParaTextStyle}>
                        {item.content1}
                      </p>
                    )}
                    {item.boldContent && (
                      <p className={styles.contentOrangeTextStyle}>
                        {item.boldContent}
                      </p>
                    )}
                    {item.content2 && (
                      <p className={styles.contentParaTextStyle}>
                        {item.content2}
                      </p>
                    )}
                    {item.content3 && (
                      <p className={styles.contentParaTextStyle}>
                        {item.content3}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.arrowBtnContainerStyle}>
            <div
              className={styles.arrowWrapperStyle}
              onClick={() => prevClick()}
            >
              <BackArrowSvg
                color={
                  indexNo > 1 ? styles.nextArrowStyle : styles.BackArrowStyle
                }
              />
            </div>
            <div
              className={styles.arrowWrapperStyle}
              onClick={() => nextClick()}
            >
              <NextArrowSvg
                color={
                  indexNo === Data?.length
                    ? styles.BackArrowStyle
                    : styles.nextArrowStyle
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className={
        focus
          ? styles.ourStoryPageFocusContainerStyle
          : styles.ourStoryPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        <title>RosholmDell | {strings.common.ourStory}</title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.positonElementStyle}>
          {renderHeader()}

          {renderSecondContainer()}
          {renderThirdContainer()}
          {renderFourthContainer()}
        </div>
        <Footer blueFooter={true} />
      </div>
    </div>
  );
};

export default OurStoryPage;
