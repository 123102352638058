import React, { useEffect, useState } from "react";
import commonStyles from "../../components/css/common.module.css";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import { plusBlackIcon } from "../../resources/images";
import { useLocation } from "react-router-dom";
import { getJobDetailsApi } from "../../networking/api/jobposts";
import ContentUi from "../../components/contentuicomponent";
import findLinksAndEmails from "../../helpers/linkfinder";
import Loader from "../../components/loader";
import { Helmet } from "react-helmet";

const JobPositionPage = () => {
  const location = useLocation();
  const data = location.search.slice(1);
  const { strings, modalOpen, focus, setFocus, currentLanguage } = useAppData();
  const [jobRequirements, setJobRequirements] = useState(false);
  const [jobResponsibilities, setJobResponsibilities] = useState(false);
  const [aboutRd, setAboutRd] = useState(false);
  const [jobData, setJobData] = useState();
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  //api for getting job details individually
  useEffect(() => {
    try {
      getJobDetailsApi(data).then((res) => {
        if (res.data.type === "success") {
          setJobData(res.data.data);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  return (
    <div
      className={
        focus
          ? styles.jobPageFocusContainerStyle
          : styles.jobPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        {jobData && (
          <title>RosholmDell | {`${jobData?.title[currentLanguage]}`}</title>
        )}
      </Helmet>
      <Navbar blueNav={true} />
      {!jobData ? (
        <Loader />
      ) : (
        <div
          className={
            modalOpen
              ? commonStyles.commonMainDownContainerStyle
              : commonStyles.commonMainContainerStyle
          }
        >
          <div className={styles.jobPageInsideContainerStyle}>
            <div className={styles.jonPositionHeaderContainerStyle}>
              <h2 className={styles.jonPositionHeaderTextStyle}>
                {strings.jobpositionPage.headerText} &nbsp;
                <span className={styles.jonPositionHeaderTextOrangeStyle}>
                  {jobData?.title[currentLanguage]}
                </span>
                {strings.jobpositionPage.headerText3}
              </h2>
              <ContentUi Data={jobData?.description} />
            </div>
            <div className={styles.jobOtionsMainContainerStyle}>
              <div className={styles.jobPositionOuterContainerStyle}>
                <div
                  className={
                    jobRequirements
                      ? styles.jobOtions2ContainerStyle
                      : styles.jobOtionsContainerStyle
                  }
                  onClick={() => {
                    setJobRequirements(!jobRequirements);
                  }}
                >
                  <p className={styles.jobOptionsHeaderTextStyle}>
                    {strings.jobpositionPage.jobrequirements}
                  </p>
                  <div
                    className={
                      jobRequirements
                        ? styles.contentOpenStyle
                        : styles.contentCloseStyle
                    }
                  >
                    <ContentUi Data={jobData?.job_requirement} />
                  </div>
                  {!jobRequirements && (
                    <div className={styles.imgWrapperStyle}>
                      <img src={plusBlackIcon} className={styles.imgStyle} />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.jobPositionOuterContainerStyle}>
                <div
                  className={
                    jobResponsibilities
                      ? styles.jobOtions2ContainerStyle
                      : styles.jobOtionsContainerStyle
                  }
                  onClick={() => {
                    setJobResponsibilities(!jobResponsibilities);
                  }}
                >
                  <p className={styles.jobOptionsHeaderTextStyle}>
                    {strings.jobpositionPage.jobresponsibilities}
                  </p>
                  <div
                    className={
                      jobResponsibilities
                        ? styles.contentOpenStyle
                        : styles.contentCloseStyle
                    }
                  >
                    <ContentUi Data={jobData?.job_responsibilities} />
                  </div>
                  {!jobResponsibilities && (
                    <div className={styles.imgWrapperStyle}>
                      <img src={plusBlackIcon} className={styles.imgStyle} />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.jobPositionOuterContainerStyle}>
                <div
                  className={
                    aboutRd
                      ? styles.jobOtions2ContainerStyle
                      : styles.jobOtionsContainerStyle
                  }
                  onClick={() => {
                    setAboutRd(!aboutRd);
                  }}
                >
                  <p className={styles.jobOptionsHeaderTextStyle}>
                    {strings.jobpositionPage.aboutRd}
                  </p>
                  <div
                    className={
                      aboutRd
                        ? styles.contentOpenStyle
                        : styles.contentCloseStyle
                    }
                  >
                    <ContentUi Data={jobData?.about_us} />
                  </div>
                  {!aboutRd && (
                    <div className={styles.imgWrapperStyle}>
                      <img src={plusBlackIcon} className={styles.imgStyle} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.jobPageBottomCardStyle}>
              <p className={styles.jobPageBottomCardTextStyle}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: findLinksAndEmails(
                      jobData?.deadline_content
                        ? jobData?.deadline_content[currentLanguage]
                        : ""
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <Footer blueFooter={true} />
        </div>
      )}
    </div>
  );
};

export default JobPositionPage;
