import React, { useEffect, useState, useRef } from "react";
import commonStyles from "../../components/css/common.module.css";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import { BackArrowSvg, NextArrowSvg } from "../../components/svgcomponents";
import NewsCard from "../../components/newscard";
import Button from "../../components/button";
import moment from "moment";
import {
  downOrangeSmallArrow,
  newsInsightsImg,
  whiteArrowRight,
  orangeCheckBox,
  circleEmpty,
} from "../../resources/images";
import MenuPopover from "../../components/popover";
import { useNavigate, Link } from "react-router-dom";
import { getPostsList } from "../../networking/api/posts";
import Loader from "../../components/loader";
import { Helmet } from "react-helmet";

const NewsAndInsights = () => {
  const navigate = useNavigate();
  const { strings, modalOpen, focus, setFocus, currentLanguage } = useAppData();
  const [anchorEl, setAnchorEl] = useState(null);
  const [postData, setPostData] = useState();
  const [indexNo, setIndexNo] = useState(0);
  const [limit, setLimit] = useState(6);
  const [featuredData, setFeaturedData] = useState();
  const [filter, setFilter] = useState(strings?.newsAndInsightsPage.allposts);
  const [filteredData, setFilteredData] = useState();
  const divRefProfile = React.useRef();
  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;
  const Posts = [
    { id: 1, name: strings?.newsAndInsightsPage.allposts, value: "" },
    { id: 2, name: strings?.newsAndInsightsPage.news, value: "News" },
    { id: 3, name: strings?.newsAndInsightsPage.insights, value: "Insights" },
  ];
  const containerRef = useRef(null);
  useEffect(() => {
    setFilter(strings?.newsAndInsightsPage.allposts);
  }, [currentLanguage]);
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    if (postData) {
      setFeaturedData(postData.filter((item) => item.featured === true));
    }
  }, [postData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  //api for getting news and insights list
  useEffect(() => {
    try {
      getPostsList().then((res) => {
        if (res.data.type === "success") {
          setPostData(res.data.data);
          setFilteredData(res.data.data);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const prevPost = () => {
    const container = containerRef.current;
    const nextItem = container.children[indexNo - 1];
    if (nextItem) {
      nextItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else {
      container.children[featuredData?.length - 1].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }

    if (indexNo >= 1) {
      setIndexNo(indexNo - 1);
    } else {
      setIndexNo(featuredData?.length - 1);
    }
  };
  const nextPost = () => {
    const container = containerRef.current;
    const nextItem = container.children[indexNo + 1];
    if (nextItem) {
      nextItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else {
      container.children[0].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
    if (indexNo < featuredData?.length - 1) {
      setIndexNo(indexNo + 1);
    } else {
      setIndexNo(0);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(divRefProfile.current);
  };
  //function for filtering list
  const filterData = (data) => {
    if (data == "") {
      setFilteredData(postData);
    } else {
      setFilteredData(postData.filter((item) => item.category === data));
    }
  };
  return (
    <div
      className={
        focus
          ? styles.newsPageFocusContainerStyle
          : styles.newsPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        <title>RosholmDell | {strings.common.newsAndInsights}</title>
      </Helmet>
      <Navbar blueNav={true} />
      {!filteredData ? (
        <Loader />
      ) : (
        <div
          className={
            modalOpen
              ? commonStyles.commonMainDownContainerStyle
              : commonStyles.commonMainContainerStyle
          }
        >
          <div className={styles.newsPageInsideContainerStyle}>
            <div className={styles.newsPageTopContainerStyle}>
              <div className={styles.headerContainerStyle}>
                <h2 className={styles.headerTextStyle}>
                  {strings.newsAndInsightsPage.headerText}
                </h2>
                <div
                  className={styles.postsContainerStyle}
                  ref={divRefProfile}
                  onClick={() => handleClick()}
                >
                  <p className={styles.allPostsTextStyle}>{filter}</p>
                  <div className={styles.arrowImgWrapperStyle}>
                    <img
                      src={downOrangeSmallArrow}
                      className={styles.imgStyle}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.newsPageTopCarousalContainerStyle}>
                <div className={styles.refContainerStyle} ref={containerRef}>
                  {featuredData &&
                    featuredData.map((item, index) => {
                      return (
                        <Link
                          key={index}
                          to={{
                            pathname: "/news&insightsview",
                            search: `${item.slug}`,
                          }}
                          target={"_blank"}
                          className={styles.cardOuterContainerStyle}
                        >
                          <p className={styles.cardDateTextStyle}>
                            {moment(item.created_at).format("MMMM YYYY")}
                          </p>
                          <div className={styles.newsImgWrapperStyle}>
                            <img
                              src={item.image}
                              className={styles.imgNewsStyle}
                            />
                            <div className={styles.imageBackgoundHoverStyle}>
                              <div className={styles.imgHoverStyle}>
                                <img
                                  src={whiteArrowRight}
                                  className={styles.imgNewsStyle}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.cardDescContainerStyle}>
                            <p className={styles.cardDescHeaderTextStyle}>
                              {item.title[currentLanguage]}
                            </p>
                            {item.content[0].type === "paragraph" && (
                              <p className={styles.cardDescTextStyle}>
                                {item.content[0].value[currentLanguage]}
                              </p>
                            )}
                          </div>
                        </Link>
                      );
                    })}
                </div>
                <div className={styles.buttonsContainerStyle}>
                  <p>
                    {indexNo + 1}/{featuredData && featuredData?.length}
                  </p>
                  <div className={styles.nextButtonContainerStyle}>
                    <div
                      className={styles.arrowButtonStyle}
                      onClick={() => prevPost()}
                    >
                      <BackArrowSvg color={styles.arrowBtnStyle} />
                    </div>
                    <div
                      className={styles.arrowButtonStyle}
                      onClick={() => nextPost()}
                    >
                      <NextArrowSvg color={styles.arrowBtnStyle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.cardsGridContainerStyle}>
                {filteredData &&
                  filteredData.slice(0, limit).map((item, index) => {
                    return (
                      <Link
                        className={styles.linkStyle}
                        key={index}
                        to={{
                          pathname: "/news&insightsview",
                          search: `${item.slug}`,
                        }}
                        target={"_blank"}
                      >
                        <NewsCard
                          date={item.created_at}
                          image={item.image}
                          headerText={item.title[currentLanguage]}
                          headerContentText={
                            item.content[0].value[currentLanguage]
                          }
                        />
                      </Link>
                    );
                  })}
              </div>
              {limit <= filteredData?.length - 1 ? (
                <Button
                  buttonName={strings.newsAndInsightsPage.loadMore}
                  btnStyle={styles.buttonStyle}
                  onClick={() => setLimit(postData?.length)}
                />
              ) : (
                <>
                  {filteredData?.length > 6 && (
                    <Button
                      buttonName={strings.newsAndInsightsPage.seeLess}
                      btnStyle={styles.buttonStyle}
                      onClick={() => setLimit(6)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <Footer blueFooter={true} />
        </div>
      )}
      <MenuPopover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        position={"center"}
      >
        <div className={styles.popoverContainerStyle}>
          {Posts.map((item, index) => {
            return (
              <div
                key={index}
                className={styles.popoverLangContainerStyle}
                onClick={() => {
                  setFilter(item.name);
                  filterData(item.value);
                }}
              >
                <div className={styles.imageWrapperStyle}>
                  <img
                    src={filter === item.name ? orangeCheckBox : circleEmpty}
                    className={styles.imgStyle}
                  />
                </div>
                <p className={styles.popoverTextSTyle}>{item.name}</p>
              </div>
            );
          })}
        </div>
      </MenuPopover>
    </div>
  );
};

export default NewsAndInsights;
