import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { getPostsList, deletePostApi } from "../../../../networking/api/posts";
import { useState } from "react";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/loader";
import { Helmet } from "react-helmet";
import { useAppData } from "../../../../providers/AppDataProvider";

const columns = [
  { id: "Title", label: "Title", minWidth: 170 },
  { id: "Slug", label: "Slug", minWidth: 100 },
  {
    id: "View",
    label: "View",
    minWidth: 170,
    align: "right",
  },
  {
    id: "Edit",
    label: "Edit",
    minWidth: 170,
    align: "right",
  },
  {
    id: "Delete",
    label: "Delete",
    minWidth: 170,
    align: "right",
  },
];

const NewsAndInsightsList = () => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [jobData, setJobData] = useState();
  const { currentLanguage, strings } = useAppData();
  useEffect(() => {
    getJobListData();
  }, []);
  //api for getting list data of news and insights
  const getJobListData = () => {
    try {
      getPostsList().then((res) => {
        if (res.data.type === "success") {
          setJobData(res.data.data);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //no of items display function in list
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //api for deleting news and insights
  const postDeletion = (id) => {
    try {
      deletePostApi(id).then((res) => {
        if (res.data.type === "success") {
          removePost(id);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //updating list after deleting post
  const removePost = (id) => {
    const newArray = jobData.filter((item) => item._id !== id);
    setJobData(newArray);
  };
  return (
    <>
      {!jobData ? (
        <Loader loaderStyles={styles.containerStyle} />
      ) : (
        <div>
          <Helmet>
            <meta name="description" content="Useful leagal services" />
            <meta name="keywords" content="Useful leagal services" />
            <title>RosholmDell | {strings.common.newsPostList}</title>
          </Helmet>
          <h1 className={styles.headerTextStyle}>News & Insights List</h1>

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobData &&
                    jobData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell>{item.title[currentLanguage]}</TableCell>
                            <TableCell>{item.slug}</TableCell>
                            <TableCell>
                              <Button
                                buttonName={"View"}
                                onClick={() =>
                                  navigate(`/news&insightsview?${item.slug}`)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                buttonName={"Edit"}
                                onClick={() =>
                                  navigate("/editnews&insights", {
                                    state: item,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                buttonName={"Delete"}
                                onClick={() => postDeletion(item._id)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={jobData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </>
  );
};
export default NewsAndInsightsList;
