import React from "react";
import styles from "./styles.module.css";
import { useAppData } from "../../providers/AppDataProvider";
import { useNavigate, useLocation } from "react-router-dom";
const Footer = (props) => {
  const { pathname } = useLocation();
  const { strings, setFocus } = useAppData();
  const navigate = useNavigate();
  return (
    <div
      className={
        props.blueFooter
          ? styles.footerMainBlueContainerStyle
          : styles.footerMainWhiteContainerStyle
      }
    >
      <div className={styles.footerInsideContainerStyle}>
        <div className={styles.footerItemsContainerStyle}>
          <div className={styles.footerItemWrapperStyle}>
            <p
              className={
                props.blueFooter
                  ? styles.footerItemHeaderWhiteTextStyle
                  : styles.footerItemHeaderBlueTextStyle
              }
            >
              {strings.common.ourOffices}
            </p>

            <div className={styles.footerItemContentWrapperStyle}>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/contactus") {
                    navigate("/contactus");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/contactus");
                    }, 150);
                  }
                }}
              >
                {strings.common.vaxjo}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/contactus") {
                    navigate("/contactus");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/contactus");
                    }, 150);
                  }
                }}
              >
                {strings.common.helsinborg}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/contactus") {
                    navigate("/contactus");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/contactus");
                    }, 150);
                  }
                }}
              >
                {strings.common.kalmar}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/contactus") {
                    navigate("/contactus");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/contactus");
                    }, 150);
                  }
                }}
              >
                {strings.common.malmo}
              </p>
              {/* <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
              >
                {strings.common.stockholm}
              </p> */}
            </div>
          </div>
          <div className={styles.footerItemWrapperStyle}>
            <p
              className={
                props.blueFooter
                  ? styles.footerItemHeaderWhiteTextStyle
                  : styles.footerItemHeaderBlueTextStyle
              }
            >
              {strings.common.pages}
            </p>

            <div className={styles.footerItemContentWrapperStyle}>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/about") {
                    navigate("/about");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/about");
                    }, 150);
                  }
                }}
              >
                {strings.common.aboutUs}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/careers") {
                    navigate("/careers");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/careers");
                    }, 150);
                  }
                }}
              >
                {strings.common.career}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/contactus") {
                    navigate("/contactus");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/contactus");
                    }, 150);
                  }
                }}
              >
                {strings.common.contactUs}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/news&insights") {
                    navigate("/news&insights");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/news&insights");
                    }, 150);
                  }
                }}
              >
                {strings.common.newsAndInsights}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/ourteam") {
                    navigate("/ourteam");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/ourteam");
                    }, 150);
                  }
                }}
              >
                {strings.common.ourTeam}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/services&solutions") {
                    navigate("/services&solutions");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/services&solutions");
                    }, 150);
                  }
                }}
              >
                {strings.common.serviceAndSolution}
              </p>
            </div>
          </div>
          <div className={styles.footerItemWrapperStyle}>
            <p
              className={
                props.blueFooter
                  ? styles.footerItemHeaderWhiteTextStyle
                  : styles.footerItemHeaderBlueTextStyle
              }
            >
              {strings.common.socilaMedia}
            </p>

            <div className={styles.footerItemContentWrapperStyle}>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/rosholmdell-advokatbyra/about/",
                    "__blank"
                  )
                }
              >
                {strings.common.linkedin}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/rosholmdelladvokatbyra/",
                    "__blank"
                  )
                }
              >
                {strings.common.instagram}
              </p>
            </div>
          </div>
          <div className={styles.footerItemWrapperStyle}>
            <p
              className={
                props.blueFooter
                  ? styles.footerItemHeaderWhiteTextStyle
                  : styles.footerItemHeaderBlueTextStyle
              }
            >
              {strings.common.links}
            </p>

            <div className={styles.footerItemContentWrapperStyle}>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/terms&conditions") {
                    navigate("/terms&conditions");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/terms&conditions");
                    }, 150);
                  }
                }}
              >
                {strings.common.termsAndCondition}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/privacynotice") {
                    navigate("/privacynotice");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/privacynotice");
                    }, 150);
                  }
                }}
              >
                {strings.common.privacyNotice}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/cookienotice") {
                    navigate("/cookienotice");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/cookienotice");
                    }, 150);
                  }
                }}
              >
                {strings.common.cookieNotice}
              </p>
              <p
                className={
                  props.blueFooter
                    ? styles.footerItemContentWhiteTextStyle
                    : styles.footerItemContentBlueTextStyle
                }
                onClick={() => {
                  if (pathname === "/whistleblowerpolicy") {
                    navigate("/whistleblowerpolicy");
                  } else {
                    setFocus(false);
                    setTimeout(() => {
                      navigate("/whistleblowerpolicy");
                    }, 150);
                  }
                }}
              >
                {strings.common.whistleBlowerPolicy}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.copyrightsContainerStyle}>
          <p
            className={
              props.blueFooter
                ? styles.copyRightsWhiteTextStyle
                : styles.copyRightsBlueTextStyle
            }
          >
            {strings.common.copyrights}
          </p>
          <p
            className={
              props.blueFooter
                ? styles.copyRightsWhiteTextStyle
                : styles.copyRightsBlueTextStyle
            }
          >
            {strings.common.registrationNo}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
