import React, { useEffect } from "react";
import AppRoutes from "./routes";
import "./App.css";
import { AppDataProvider } from "./providers/AppDataProvider";
import { UserDataProvider } from "./providers/UserDataProvider";

function App() {
  useEffect(() => {
    if (window.location.href.includes("rd.viljetech.com")) {
      window.location.replace("https://rosholmdell.se");
    }
  }, []);
  return (
    <UserDataProvider>
      <AppDataProvider>
        <AppRoutes />
      </AppDataProvider>
    </UserDataProvider>
  );
}

export default App;
