import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  getTeamMembersList,
  deleteTeamMemberApi,
} from "../../../../networking/api/team";
import { useState } from "react";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/modal";
import Loader from "../../../../components/loader";
import { Helmet } from "react-helmet";
import { useAppData } from "../../../../providers/AppDataProvider";

const columns = [
  { id: "Name", label: "Name", minWidth: 170 },
  { id: "Mail", label: "Mail", minWidth: 100 },
  { id: "Order", label: "Order", minWidth: 100 },
  {
    id: "View",
    label: "View",
    minWidth: 170,
    align: "right",
  },
  {
    id: "Edit",
    label: "Edit",
    minWidth: 170,
    align: "right",
  },
  {
    id: "Delete",
    label: "Delete",
    minWidth: 170,
    align: "right",
  },
];

const TeamMemberList = () => {
  const navigate = useNavigate();
  const { strings } = useAppData();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [jobData, setJobData] = useState();
  const [is_modalVisible, setIs_ModalVisible] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);
  const [phoneCopied, setPhoneCopied] = useState(false);
  const [modalData, setModalData] = useState();
  useEffect(() => {
    getTeamListData();
  }, []);
  const copiedEmail = () => {
    setEmailCopied(true);
    navigator.clipboard.writeText(modalData.email);
    setTimeout(() => {
      setEmailCopied(false);
    }, 1500);
  };
  const copiedPhoneNumber = () => {
    setPhoneCopied(true);
    navigator.clipboard.writeText(modalData.phone_number);
    setTimeout(() => {
      setPhoneCopied(false);
    }, 1500);
  };
  const linkedinButtonAction = () => {
    window.open(modalData.linkedin_url, "__blank");
  };
  //api for getting team members list
  const getTeamListData = () => {
    try {
      getTeamMembersList().then((res) => {
        if (res.data.type === "success") {
          setJobData(res.data.data);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //api for deleting team member individually
  const teamMemberDeletion = (id) => {
    try {
      deleteTeamMemberApi(id).then((res) => {
        if (res.data.type === "success") {
          removeTeammemberPost(id);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //updating team member list after deletion
  const removeTeammemberPost = (id) => {
    const newArray = jobData.filter((item) => item._id !== id);
    setJobData(newArray);
  };
  const renderModalPopUp = () => {
    return (
      <Modal
        closeModal={() => {
          setIs_ModalVisible(false);
          setModalData();
        }}
        emailBtnAction={() => {
          copiedEmail();
        }}
        phoneBtnAction={() => {
          copiedPhoneNumber();
        }}
        data={modalData}
        emailCopied={emailCopied}
        phoneCopied={phoneCopied}
        linkedinBtnAction={() => linkedinButtonAction()}
      />
    );
  };
  return (
    <div>
      {!jobData ? (
        <Loader loaderStyles={styles.containerStyle} />
      ) : (
        <div className={styles.mainContainerStyle}>
          <Helmet>
            <meta name="description" content="Useful leagal services" />
            <meta name="keywords" content="Useful leagal services" />
            <title>RosholmDell | {strings.common.teamList}</title>
          </Helmet>
          <h1 className={styles.headerTextStyle}>Team Members List</h1>
          {is_modalVisible ? (
            <div className={styles.modalContainerStyle}>
              {renderModalPopUp()}
            </div>
          ) : (
            <>
              {jobData && (
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: "100%" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell key={column.id}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobData &&
                          jobData
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((item, index) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                >
                                  <TableCell>{item.name}</TableCell>
                                  <TableCell>{item.email}</TableCell>
                                  <TableCell>{item.order}</TableCell>
                                  <TableCell>
                                    <Button
                                      buttonName={"View"}
                                      onClick={() => {
                                        setModalData(item);
                                        setIs_ModalVisible(true);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      buttonName={"Edit"}
                                      onClick={() =>
                                        navigate("/editteammember", {
                                          state: item,
                                        })
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      buttonName={"Delete"}
                                      onClick={() =>
                                        teamMemberDeletion(item._id)
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={jobData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default TeamMemberList;
