import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import commonStyles from "../../../components/css/common.module.css";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import { useAppData } from "../../../providers/AppDataProvider";
import {
  downOrangeSmallArrow,
  plusBlackIcon,
  orangeCheckBox,
  ViktorJohansson,
  circleEmpty,
} from "../../../resources/images";
import ProfileCard from "../../../components/profilecard";
import MenuPopover from "../../../components/popover";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const IpPortfolioManagementPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { strings, modalOpen, focus, setFocus } = useAppData();
  const [ipportfolio, setIpportfolio] = useState(false);
  const [needs, setNeeds] = useState(false);
  const [offers, setOffers] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const divRefProfile = React.useRef();
  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(divRefProfile.current);
  };
  const PointsData = [
    strings.ipportfolioManagementPage.ipPortfolioPoints.two[1],
    strings.ipportfolioManagementPage.ipPortfolioPoints.two[2],
    strings.ipportfolioManagementPage.ipPortfolioPoints.two[3],
    strings.ipportfolioManagementPage.ipPortfolioPoints.two[4],
    strings.ipportfolioManagementPage.ipPortfolioPoints.two[5],
  ];
  const needsData = [
    strings.ipportfolioManagementPage.needsIpportfolioPoints.two[1],
    strings.ipportfolioManagementPage.needsIpportfolioPoints.two[2],
    strings.ipportfolioManagementPage.needsIpportfolioPoints.two[3],
    strings.ipportfolioManagementPage.needsIpportfolioPoints.two[4],
    strings.ipportfolioManagementPage.needsIpportfolioPoints.two[5],
    strings.ipportfolioManagementPage.needsIpportfolioPoints.two[6],
  ];

  const Solutions = [
    {
      title: strings.servicePage.whistleblowingSolution,
      url: "/services&solutions/whistleblowingsolution",
    },
    {
      title: strings.servicePage.employmentLawSolution,
      url: "/services&solutions/employmentlawasaservice",
    },
    {
      title: strings.servicePage.dpoAsService,
      url: "/services&solutions/dpoasaservice",
    },
    {
      title: strings.servicePage.ipportfoliomanagment,
      url: "/services&solutions/ipportfoliomanagement",
    },
  ];
  const userData = {
    name: "Viktor Johansson",
    designation: "Associate",
    image: ViktorJohansson,
    email: "viktor.johansson@rdlaw.se",
    mobile: "+46 70 317 77 80",
  };
  return (
    <div
      className={
        focus
          ? styles.dpoPageFocusContainerStyle
          : styles.dpoPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        <title>RosholmDell | {strings.servicePage.ipportfoliomanagment}</title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.pageInsideContainerStyle}>
          <div className={styles.headerContainerStyle}>
            <div className={styles.headerTopContainerStyle}>
              <div
                className={styles.linkContainerStyle}
                onClick={() => handleClick()}
                ref={divRefProfile}
              >
                <p className={styles.headerLinkListTextSTyle}>
                  {strings.servicePage.ipportfoliomanagment.toLocaleUpperCase()}
                </p>
                <div className={styles.arrowImgWrapperStyle}>
                  <img
                    src={downOrangeSmallArrow}
                    className={styles.arrImgStyle}
                  />
                </div>
              </div>
              <h3 className={styles.headerTextStyle}>
                {strings.ipportfolioManagementPage.title}
                <span className={styles.headerTextOrangeStyle}>
                  {strings.ipportfolioManagementPage.orangeText}
                </span>
              </h3>
            </div>
            <p className={styles.contentTextStyle}>
              {strings.ipportfolioManagementPage.titleDesc}
            </p>
          </div>
          <div className={styles.middleContainerStyle}>
            <div className={styles.middleInsideContainerStyle}>
              <div
                className={
                  ipportfolio
                    ? styles.itemContainerStyle
                    : styles.itemContainerStyle2
                }
                onClick={() => {
                  setIpportfolio(!ipportfolio);
                }}
              >
                <p className={styles.contentTextStyle}>
                  {strings.ipportfolioManagementPage.ipPortfolio}
                </p>

                <div
                  className={
                    ipportfolio
                      ? styles.contentContainerStyle
                      : styles.contentContentOpenStyle
                  }
                >
                  <p className={styles.contentTextStyle}>
                    {strings.ipportfolioManagementPage.ipPortfolioPoints.one}
                  </p>

                  <ul className={styles.listStyles}>
                    {PointsData.map((item, index) => (
                      <li key={index} className={styles.contentTextStyle}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                {!ipportfolio && (
                  <div className={styles.imgWrapperStyle}>
                    <img src={plusBlackIcon} className={styles.imgStyle} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.middleInsideContainerStyle}>
              <div
                className={
                  needs ? styles.itemContainerStyle : styles.itemContainerStyle2
                }
                onClick={() => {
                  setNeeds(!needs);
                }}
              >
                <p className={styles.contentTextStyle}>
                  {strings.ipportfolioManagementPage.needsIpportfolio}
                </p>

                <div
                  className={
                    needs
                      ? styles.contentContainerStyle
                      : styles.contentContentOpenStyle
                  }
                >
                  <p className={styles.contentTextStyle}>
                    {
                      strings.ipportfolioManagementPage.needsIpportfolioPoints
                        .one
                    }
                  </p>

                  <ul className={styles.listStyles}>
                    {needsData.map((item, index) => (
                      <li key={index} className={styles.contentTextStyle}>
                        {item}
                      </li>
                    ))}
                  </ul>
                  <p className={styles.contentTextStyle}>
                    {
                      strings.ipportfolioManagementPage.needsIpportfolioPoints
                        .three
                    }
                  </p>
                </div>
                {!needs && (
                  <div className={styles.imgWrapperStyle}>
                    <img src={plusBlackIcon} className={styles.imgStyle} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.middleInsideContainerStyle}>
              <div
                className={
                  offers
                    ? styles.itemContainerStyle
                    : styles.itemContainerStyle2
                }
                onClick={() => {
                  setOffers(!offers);
                }}
              >
                <p className={styles.contentTextStyle}>
                  {strings.ipportfolioManagementPage.roshlommdellOffer}
                </p>
                <div
                  className={
                    offers
                      ? styles.contentContainerStyle
                      : styles.contentContentOpenStyle
                  }
                >
                  <p className={styles.contentTextStyle}>
                    {
                      strings.ipportfolioManagementPage.roshlommdellOfferPoints
                        .one
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    {
                      strings.ipportfolioManagementPage.roshlommdellOfferPoints
                        .two
                    }
                  </p>
                  <p className={styles.contentTextStyle}>
                    {
                      strings.ipportfolioManagementPage.roshlommdellOfferPoints
                        .three
                    }
                  </p>
                </div>
                {!offers && (
                  <div className={styles.imgWrapperStyle}>
                    <img src={plusBlackIcon} className={styles.imgStyle} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.userContainerStyle}>
            <div className={styles.userDetailsStyle}>
              <a
                className={styles.userContactLinksTextStyle}
                href={`mailto:${userData.email}`}
              >
                {userData.email}
              </a>
              <a
                className={styles.userContactLinksTextStyle}
                href={`tel:${userData.mobile}`}
              >
                {userData.mobile}
              </a>
              <a
                className={styles.userContactLinksTextStyle}
                onClick={() => navigate("/contactus")}
              >
                {strings.common.writeToUsSmall}
              </a>
            </div>
            <div className={styles.cardContainerStyle}>
              <ProfileCard
                data={userData}
                cardContainerStyle={styles.cardStyle}
                arrWrapperStyle={styles.cardArrowStyle}
                onClick={() => console.log("c")}
              />
            </div>
          </div>
        </div>
        <Footer blueFooter={true} />
      </div>
      <MenuPopover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        position={"center"}
      >
        <div className={styles.popoverContainerStyle}>
          {Solutions.map((item, index) => {
            return (
              <div
                className={styles.popoverLangContainerStyle}
                onClick={() => navigate(item.url)}
              >
                <div className={styles.imageWrapperStyle}>
                  <img
                    src={pathname === item.url ? orangeCheckBox : circleEmpty}
                    className={styles.imgStyle}
                  />
                </div>
                <p className={styles.popoverTextSTyle}>
                  {item.title.toLocaleUpperCase()}
                </p>
              </div>
            );
          })}
        </div>
      </MenuPopover>
    </div>
  );
};

export default IpPortfolioManagementPage;
