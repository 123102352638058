import React from "react";
import styles from "./styles.module.css";
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAppData } from "../../../providers/AppDataProvider";

const DashboardPage = () => {
  const navigate = useNavigate();
  const { strings } = useAppData();
  const Data = [
    {
      id: 1,
      name: "Create job",
      routeName: "/createjob",
    },
    {
      id: 2,
      name: "Job list",
      routeName: "/jobslist",
    },
    {
      id: 3,
      name: "Create Team Member",
      routeName: "/createteammember",
    },
    {
      id: 4,
      name: "Team Member list",
      routeName: "/teammemberlist",
    },
    {
      id: 5,
      name: "Create News&insights Post",
      routeName: "/createnews&insights",
    },
    {
      id: 6,
      name: "News&insights Posts List",
      routeName: "/news&insightslist",
    },
    {
      id: 7,
      name: "Create Testimonial",
      routeName: "/createtestimonial",
    },
    {
      id: 8,
      name: "Testimonial List",
      routeName: "/testimoniallist",
    },
  ];
  return (
    <div className={styles.dashBoardMainContainerStyle}>
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        <title>RosholmDell | {strings.common.dashboard}</title>
      </Helmet>
      <div className={styles.dashBoardContainerStyle}>
        {Data.map((item, index) => {
          return (
            <Button
              key={index}
              buttonName={item.name}
              btnStyle={styles.buttonStyles}
              onClick={() => navigate(item.routeName)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DashboardPage;
