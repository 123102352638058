import React, { useEffect } from "react";
import commonStyles from "../../components/css/common.module.css";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const PrivacyNoticePage = () => {
  const navigate = useNavigate();
  const { strings, modalOpen, focus, setFocus } = useAppData();
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  const Data1 = [
    strings.privacyNoticePage.subtitlethreeDesc.two[1],
    strings.privacyNoticePage.subtitlethreeDesc.two[2],
    strings.privacyNoticePage.subtitlethreeDesc.two[3],
    strings.privacyNoticePage.subtitlethreeDesc.two[4],
    strings.privacyNoticePage.subtitlethreeDesc.two[5],
    strings.privacyNoticePage.subtitlethreeDesc.two[6],
  ];
  const Data2 = [
    strings.privacyNoticePage.subtitlefiveDesc.two[1],
    strings.privacyNoticePage.subtitlefiveDesc.two[2],
    strings.privacyNoticePage.subtitlefiveDesc.two[3],
    strings.privacyNoticePage.subtitlefiveDesc.two[4],
    strings.privacyNoticePage.subtitlefiveDesc.two[5],
    strings.privacyNoticePage.subtitlefiveDesc.two[6],
    strings.privacyNoticePage.subtitlefiveDesc.two[7],
    strings.privacyNoticePage.subtitlefiveDesc.two[8],
  ];
  const Data3 = [
    strings.privacyNoticePage.subtitlesixDesc.two[1],
    strings.privacyNoticePage.subtitlesixDesc.two[2],
    strings.privacyNoticePage.subtitlesixDesc.two[3],
    strings.privacyNoticePage.subtitlesixDesc.two[4],
    strings.privacyNoticePage.subtitlesixDesc.two[5],
    strings.privacyNoticePage.subtitlesixDesc.two[6],
    strings.privacyNoticePage.subtitlesixDesc.two[7],
    strings.privacyNoticePage.subtitlesixDesc.two[8],
    strings.privacyNoticePage.subtitlesixDesc.two[9],
    strings.privacyNoticePage.subtitlesixDesc.two[10],
    strings.privacyNoticePage.subtitlesixDesc.two[11],
    strings.privacyNoticePage.subtitlesixDesc.two[12],
    strings.privacyNoticePage.subtitlesixDesc.two[13],
    strings.privacyNoticePage.subtitlesixDesc.two[14],
    strings.privacyNoticePage.subtitlesixDesc.two[15],
  ];
  const Data4 = [
    strings.privacyNoticePage.subtitlenineDesc.four.desc1[1],
    strings.privacyNoticePage.subtitlenineDesc.four.desc1[2],
    strings.privacyNoticePage.subtitlenineDesc.four.desc1[3],
    strings.privacyNoticePage.subtitlenineDesc.four.desc1[4],
    strings.privacyNoticePage.subtitlenineDesc.four.desc1[5],
    strings.privacyNoticePage.subtitlenineDesc.four.desc1[6],
  ];
  const Data5 = [
    strings.privacyNoticePage.subtitlenineDesc.six.desc1[1],
    strings.privacyNoticePage.subtitlenineDesc.six.desc1[2],
    strings.privacyNoticePage.subtitlenineDesc.six.desc1[3],
  ];
  return (
    <div
      className={
        focus
          ? styles.whistlePolicyPageFocusContainerStyle
          : styles.whistlePolicyPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        <title>RosholmDell | {strings.common.privacyNotice}</title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.pageInsideContainerStyle}>
          <h2 className={styles.headerTextStyle}>
            {strings.privacyNoticePage.title}
          </h2>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitleone}
            </p>
            <div className={styles.contentContainerStyle}>
              <p
                className={styles.contentTextStyle}
                dangerouslySetInnerHTML={{
                  __html: strings.privacyNoticePage.subtitleoneDesc.one,
                }}
              />
              <p
                className={styles.contentTextStyle}
                dangerouslySetInnerHTML={{
                  __html: strings.privacyNoticePage.subtitleoneDesc.two,
                }}
              />
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitleoneDesc.three}&nbsp;
                <span
                  onClick={() => navigate("/terms&conditions")}
                  className={styles.contentBlueTextstyle}
                >
                  {strings.privacyNoticePage.subtitleoneDesc.four}
                </span>
                &nbsp;
                {strings.privacyNoticePage.subtitleoneDesc.five}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitletwo}
            </p>
            <div className={styles.contentContainerStyle}>
              <p
                className={styles.contentTextStyle}
                dangerouslySetInnerHTML={{
                  __html: strings.privacyNoticePage.subtitletwoDesc.one,
                }}
              />
              <p
                className={styles.contentTextStyle}
                dangerouslySetInnerHTML={{
                  __html: strings.privacyNoticePage.subtitletwoDesc.two,
                }}
              />
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitlethree}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlethreeDesc.one}
              </p>
              <ul>
                {Data1.map((item, index) => {
                  return (
                    <li key={index} className={styles.contentTextStyle}>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitlefour}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlefourDesc.one}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitlefive}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlefiveDesc.one}
              </p>
              <ul>
                {Data2.map((item, index) => {
                  return (
                    <li key={index} className={styles.contentTextStyle}>
                      {item}
                    </li>
                  );
                })}
              </ul>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlefiveDesc.three}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlefiveDesc.four}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlefiveDesc.five}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitlesix}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesixDesc.one}
              </p>
              <ul>
                {Data3.map((item, index) => {
                  return (
                    <li key={index} className={styles.contentTextStyle}>
                      {item}
                    </li>
                  );
                })}
              </ul>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesixDesc.three}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesixDesc.four}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesixDesc.five}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesixDesc.six}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesixDesc.seven}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesixDesc.eight}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitleseven}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesevenDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesevenDesc.two}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesevenDesc.three}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesevenDesc.four}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesevenDesc.five}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitleeight}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitleeightDesc.one}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitlenine}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlenineDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.privacyNoticePage.subtitlenineDesc.two.title}
                </span>
                {strings.privacyNoticePage.subtitlenineDesc.two.desc}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.privacyNoticePage.subtitlenineDesc.three.title}
                </span>
                {strings.privacyNoticePage.subtitlenineDesc.three.desc}
              </p>

              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.privacyNoticePage.subtitlenineDesc.four.title}
                </span>
                {strings.privacyNoticePage.subtitlenineDesc.four.desc}
              </p>
              <ul>
                {Data4.map((item, index) => {
                  return (
                    <li key={index} className={styles.contentTextStyle}>
                      {item}
                    </li>
                  );
                })}
              </ul>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlenineDesc.five}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.privacyNoticePage.subtitlenineDesc.six.title}
                </span>
                {strings.privacyNoticePage.subtitlenineDesc.six.desc}
              </p>
              <ul>
                {Data5.map((item, index) => {
                  return (
                    <li key={index} className={styles.contentTextStyle}>
                      {item}
                    </li>
                  );
                })}
              </ul>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlenineDesc.seven}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.privacyNoticePage.subtitlenineDesc.eight.title}
                </span>
                {strings.privacyNoticePage.subtitlenineDesc.eight.desc}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.privacyNoticePage.subtitlenineDesc.nine.title}
                </span>
                {strings.privacyNoticePage.subtitlenineDesc.nine.desc}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitleten}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitletenDesc.one}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitleeleven}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitleelevenDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitleelevenDesc.two}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitleelevenDesc.three}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitleelevenDesc.four}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitleelevenDesc.five}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitletwelve}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitletwelveDesc.one}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitlethirteen}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlethirteenDesc.one}
                &nbsp;
                <a
                  href="https://www.imy.se/en/RosholmDell"
                  target="_blank"
                  className={styles.contentBlueTextstyle}
                >
                  {strings.privacyNoticePage.subtitlethirteenDesc.two}
                </a>
                &nbsp;
                {strings.privacyNoticePage.subtitlethirteenDesc.three}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitlefourteen}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlefourteenDesc.one}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitlefifteen}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlefifteenDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlefifteenDesc.two}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.privacyNoticePage.subtitlesixteen}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.privacyNoticePage.subtitlesixteenDesc.one}
              </p>
            </div>
          </div>
        </div>
        <Footer blueFooter={true} />
      </div>
    </div>
  );
};

export default PrivacyNoticePage;
