import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { useAppData } from "../../providers/AppDataProvider";
import styles from "./styles.module.css";
import commonStyles from "../../components/css/common.module.css";
import ProfileCard from "../../components/profilecard";
import Modal from "../../components/modal";
import { gallery } from "../../resources/images";
import { getTeamMembersList } from "../../networking/api/team";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const OurTeam = () => {
  const navigate = useNavigate();
  const { strings, modalOpen, focus, setFocus } = useAppData();
  const [is_modalVisible, setIs_ModalVisible] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);
  const [phoneCopied, setPhoneCopied] = useState(false);
  const [team, setTeam] = useState();
  const [modalData, setModalData] = useState();
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  useEffect(() => {
    try {
      getTeamMembersList().then((res) => {
        if (res.data.type === "success") {
          setTeam(res.data.data);
        } else {
          console.log(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const renderDevider = () => {
    return <div className={styles.deviderStyle}></div>;
  };

  const renderProfiles = () => {
    return (
      <div className={styles.teamProfileContainerStyle}>
        {renderDevider()}
        <div className={styles.profileContainerStyle}>
          {team &&
            team.map((profile, index) => (
              <div key={index} className={styles.profileCardContainerStyle}>
                <ProfileCard
                  data={profile}
                  cardDescStyle={styles.profileDescStyle}
                  cardTitleStyle={styles.profileTitleStyle}
                  onClick={() => {
                    navigate(`/teammemberdetails/${profile._id}`);
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderModalPopUp = () => {
    return (
      <Modal
        closeModal={() => {
          setIs_ModalVisible(false);
          setModalData();
        }}
        emailBtnAction={() => {
          copiedEmail();
        }}
        phoneBtnAction={() => {
          copiedPhoneNumber();
        }}
        data={modalData}
        emailCopied={emailCopied}
        phoneCopied={phoneCopied}
        linkedinBtnAction={() => linkedinButtonAction()}
      />
    );
  };
  const copiedEmail = () => {
    setEmailCopied(true);
    window.navigator.clipboard.writeText(modalData.email);
    setTimeout(() => {
      setEmailCopied(false);
    }, 1000);
  };
  const copiedPhoneNumber = () => {
    setPhoneCopied(true);
    window.navigator.clipboard.writeText(modalData.phone_number);
    setTimeout(() => {
      setPhoneCopied(false);
    }, 1000);
  };
  const linkedinButtonAction = () => {
    window.open(modalData.linkedin_url, "__blank");
  };
  return (
    <div
      className={
        focus
          ? styles.ourTeamPageFocusContainerStyle
          : styles.ourTeamPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        <title>RosholmDell | {strings.common.ourTeam}</title>
      </Helmet>
      {is_modalVisible ? (
        renderModalPopUp()
      ) : (
        <div>
          <Navbar whiteLogoNav={true} />
          {!team ? (
            <Loader />
          ) : (
            <div
              className={
                modalOpen
                  ? commonStyles.commonMainDownContainerStyle
                  : commonStyles.commonMainContainerStyle
              }
            >
              <div className={styles.backgroundColorStyle}>
                <div className={styles.outTeamInsideContainerStyle}>
                  <div className={styles.headerTextContainerStyle}>
                    <h2 className={styles.titleTextStyle}>
                      {strings.common.ourTeam}
                    </h2>
                  </div>
                  {renderProfiles()}
                </div>
              </div>
              <Footer blueFooter={false} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OurTeam;
