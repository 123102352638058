import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { uploadImageApi } from "../../networking/api/imageupload";
import Button from "../button";
import ButtonLoader from "../buttonloader";

const ImageUpload = (props) => {
  const [imgUploadError, setImageUploadError] = useState("");
  const [imgUploadSuccess, setImageUploadSuccess] = useState("");
  const [image, setImage] = useState();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (props.successEmpty) {
      setImageUploadError();
      setImageUploadSuccess();
    }
  }, [props.successEmpty]);
  //image upload function
  const uploadImage = () => {
    setLoader(true);
    setImageUploadError();
    setImageUploadSuccess();
    const imgdata = new FormData();
    imgdata.append("image", props.imageData);
    try {
      uploadImageApi(imgdata).then((res) => {
        if (res.data.type === "success") {
          props.setImageUrl(res.data.data);
          setLoader(false);
          setImageUploadSuccess("Image Uploaded Successfully");
        } else {
          setImageUploadError(res.data.message);
        }
      });
    } catch (error) {
      setImageUploadError(error);
    }
  };
  //image url generation from uploaded image data
  const imageUrlgen = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };
  return (
    <div className={styles.inputContainerStyle}>
      <input
        ref={props.inputRef}
        type={"file"}
        className={styles.inputStyle}
        onChange={(e) => {
          props.imageChange(e);
          imageUrlgen(e);
          setImageUploadError();
          setImageUploadSuccess();
        }}
        name={props.selectedImage}
      />
      {(props.imageData || props.selectedImage) && (
        <div
          className={[
            props.imageContainerStyle,
            styles.imageContainerStyle,
          ].join(" ")}
        >
          {(props.imageData || props.selectedImage) && (
            <div
              className={[
                props.imageWrapperStyle,
                styles.imageWrapperStyle,
              ].join(" ")}
            >
              <img
                className={styles.imgStyle}
                src={(props.imageData && image) || props.selectedImage}
              />
            </div>
          )}
          <div>
            {imgUploadSuccess && (
              <p className={styles.successTextStyle}>{imgUploadSuccess}</p>
            )}
            {imgUploadError && (
              <p className={styles.errorTextStyle}>{imgUploadError}</p>
            )}
            <Button
              buttonName={loader ? <ButtonLoader /> : "Upload"}
              btnStyle={styles.buttonStyle}
              onClick={() => {
                uploadImage();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
