import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import TextInput from "../../../../components/textInput";
import Button from "../../../../components/button";
import ContentComponent from "../../../../components/contentcomponent";
import {
  getJobDetailsApi,
  updateJobDetailsApi,
} from "../../../../networking/api/jobposts";
import { useLocation } from "react-router-dom";
import { CheckIconSvg } from "../../../../components/svgcomponents";
import Loader from "../../../../components/loader";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAppData } from "../../../../providers/AppDataProvider";

const UpdateJobDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { strings } = useAppData();
  const slugName = location.state;
  const [jobDetails, setJobDetails] = useState({
    job_id: "",
    title: { en: "", sw: "" },
    slug: "",
    description: [],
    job_requirement: [],
    job_responsibilities: [],
    deadline_content: { en: "", sw: "" },
    about_us: [],
  });
  const [description, setDescription] = useState([]);
  const [jobRequirement, setJobRequirement] = useState([]);
  const [jobResponsibilities, setJobResponsibilities] = useState([]);
  const [aboutUs, setAboutUs] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loader, setLoader] = useState(true);
  //api for getting individual job data for editing
  useEffect(() => {
    try {
      getJobDetailsApi(slugName).then((res) => {
        if (res.data.type === "success") {
          setLoader(false);
          setJobDetails({
            ...jobDetails,
            job_id: res.data.data._id,
            title: res.data.data.title,
            slug: res.data.data.slug,
            deadline_content: res.data.data.deadline_content,
          });
          setDescription(res.data.data.description);
          setJobRequirement(res.data.data.job_requirement);
          setJobResponsibilities(res.data.data.job_responsibilities);
          setAboutUs(res.data.data.about_us);
        } else {
          setError(res.data.message);
        }
      });
    } catch (error) {
      setError(error);
    }
  }, []);
  const isValueEmpty = (myArray) => {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].type === "paragraph") {
        if (myArray[i].value.en === "") {
          return true;
        }
        if (myArray[i].value.sw === "") {
          return true;
        }
      }
      if (myArray[i].type === "image") {
        if (myArray[i].image_link === "") {
          return true;
        }
      }
      if (myArray[i].type === "link") {
        if (myArray[i].link.title.en === "") {
          return true;
        }
        if (myArray[i].link.title.sw === "") {
          return true;
        }
        if (myArray[i].link.url === "") {
          return true;
        }
      }
      if (myArray[i].type === "list") {
        for (let j = 0; j < myArray[i].items.length; j++) {
          if (myArray[i].items[j].en === "") {
            return true;
          }
          if (myArray[i].items[j].sw === "") {
            return true;
          }
        }
      }
    }
    return false;
  };
  const isValueEmptyErr = (myArray) => {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].type === "paragraph") {
        if (myArray[i].value.en === "") {
          return "paragraph english value is required";
        }
        if (myArray[i].value.sw === "") {
          return "paragraph swedish value is required";
        }
      }
      if (myArray[i].type === "image") {
        if (myArray[i].image_link === "") {
          return "content image is required";
        }
      }
      if (myArray[i].type === "link") {
        if (myArray[i].link.title.en === "") {
          return "link title english value is required";
        }
        if (myArray[i].link.title.sw === "") {
          return "link title swedish value is required";
        }
        if (myArray[i].link.url === "") {
          return "link title url value is required";
        }
      }
      if (myArray[i].type === "list") {
        for (let j = 0; j < myArray[i].items.length; j++) {
          if (myArray[i].items[j].en === "") {
            return "list item english value is required";
          }
          if (myArray[i].items[j].sw === "") {
            return "list item swedish value is required";
          }
        }
      }
    }
    return false;
  };
  //validations for checking inputs value
  const onSubmitFunction = () => {
    if (jobDetails.title.en === "") {
      setError("English title is Required");
    } else if (jobDetails.title.sw === "") {
      setError("Swedish title is Required");
    } else if (description.length === 0) {
      setError("Description is Required");
    } else if (description.length > 0 && isValueEmpty(description)) {
      setError(isValueEmptyErr(description));
    } else if (jobRequirement.length === 0) {
      setError("Job Requirements is Required");
    } else if (jobRequirement.length > 0 && isValueEmpty(jobRequirement)) {
      setError(isValueEmptyErr(jobRequirement));
    } else if (jobResponsibilities.length === 0) {
      setError("Job Responsibilities is Required");
    } else if (
      jobResponsibilities.length > 0 &&
      isValueEmpty(jobResponsibilities)
    ) {
      setError(isValueEmptyErr(jobResponsibilities));
    } else if (aboutUs.length === 0) {
      setError("About us is Required");
    } else if (aboutUs.length > 0 && isValueEmpty(aboutUs)) {
      setError(isValueEmptyErr(aboutUs));
    } else if (jobDetails.deadline_content.en === "") {
      setError("Deadline english Content is Required");
    } else if (jobDetails.deadline_content.sw === "") {
      setError("Deadline swedish Content is Required");
    } else {
      updateApiFunction();
    }
  };
  //api for updating edited job
  const updateApiFunction = () => {
    setError("");
    setSuccess("");
    try {
      updateJobDetailsApi({
        ...jobDetails,
        description: description,
        job_requirement: jobRequirement,
        job_responsibilities: jobResponsibilities,
        about_us: aboutUs,
      }).then((res) => {
        if (res.data.type === "success") {
          setSuccess(res.data.message);
          alert(res.data.message);
          navigate("/jobslist");
        } else {
          setError(res.data.message);
        }
      });
    } catch (error) {
      setError(error);
    }
  };
  return (
    <div>
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        <title>RosholmDell | {strings.common.updateJob}</title>
      </Helmet>
      {loader ? (
        <Loader loaderStyles={styles.containerStyle} />
      ) : (
        <div className={styles.createJobContainerStyle}>
          <h1>Update Job Details</h1>
          <div className={styles.titleContainerStyle}>
            <h3>Title</h3>
            <div className={styles.langContainerStyles}>
              <p>English</p>
              <div className={styles.inputContainerStyle}>
                <TextInput
                  placeholder={"English title..."}
                  value={jobDetails.title.en}
                  onChange={(e) => {
                    setJobDetails({
                      ...jobDetails,
                      title: {
                        ...jobDetails.title,
                        en: e.target.value.trimStart(),
                      },
                    });
                    setError();
                    setSuccess();
                  }}
                />
                {jobDetails.title.en.length > 1 && (
                  <div className={styles.closeIconStyle}>
                    <CheckIconSvg color={"#007f00"} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.langContainerStyles}>
              <p>Swedish</p>
              <div className={styles.inputContainerStyle}>
                <TextInput
                  placeholder={"Swedish title..."}
                  value={jobDetails.title.sw}
                  onChange={(e) => {
                    setJobDetails({
                      ...jobDetails,
                      title: {
                        ...jobDetails.title,
                        sw: e.target.value.trimStart(),
                      },
                    });
                    setError();
                    setSuccess();
                  }}
                />
                {jobDetails.title.sw.length > 1 && (
                  <div className={styles.closeIconStyle}>
                    <CheckIconSvg color={"#007f00"} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <ContentComponent
            headerText={"Description"}
            Array={description}
            SetArray={setDescription}
          />
          <ContentComponent
            headerText={"JobRequirements"}
            Array={jobRequirement}
            SetArray={setJobRequirement}
          />
          <ContentComponent
            headerText={"JobResponsibilities"}
            Array={jobResponsibilities}
            SetArray={setJobResponsibilities}
          />
          <ContentComponent
            headerText={"About Us"}
            Array={aboutUs}
            SetArray={setAboutUs}
          />

          <div className={styles.titleContainerStyle}>
            <h3>Deadline Content</h3>
            <div className={styles.langContainerStyles}>
              <p>English</p>
              <div className={styles.inputContainerStyle}>
                <TextInput
                  placeholder={"deadline english content..."}
                  value={jobDetails.deadline_content.en}
                  onChange={(e) => {
                    setJobDetails({
                      ...jobDetails,
                      deadline_content: {
                        ...jobDetails.deadline_content,
                        en: e.target.value,
                      },
                    });
                    setError();
                    setSuccess();
                  }}
                />
                {jobDetails.deadline_content.en.length > 1 && (
                  <div className={styles.closeIconStyle}>
                    <CheckIconSvg color={"#007f00"} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.langContainerStyles}>
              <p>swedish</p>
              <div className={styles.inputContainerStyle}>
                <TextInput
                  placeholder={"deadline swedish content..."}
                  value={jobDetails.deadline_content.sw}
                  onChange={(e) => {
                    setJobDetails({
                      ...jobDetails,
                      deadline_content: {
                        ...jobDetails.deadline_content,
                        sw: e.target.value,
                      },
                    });
                    setError();
                    setSuccess();
                  }}
                />
                {jobDetails.deadline_content.sw.length > 1 && (
                  <div className={styles.closeIconStyle}>
                    <CheckIconSvg color={"#007f00"} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            {error && <p className={styles.errorMessageStyle}>{error}</p>}
            {success && <p className={styles.successMessageStyle}>{success}</p>}
            <Button
              buttonName={"Update"}
              onClick={() => onSubmitFunction()}
              btnStyle={styles.buttonStyle}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateJobDetails;
