import React, { useState, useRef } from "react";
import styles from "./styles.module.css";
import Button from "../../../../components/button";
import TextInput from "../../../../components/textInput";
import {
  createTeamMemberApi,
  updateTeamMemberApi,
} from "../../../../networking/api/team";
import { useLocation } from "react-router-dom";
import {
  CheckIconSvg,
  CloseIconSvg,
} from "../../../../components/svgcomponents";
import ImageUpload from "../../../../components/imageuploadcomponent";
import { useNavigate } from "react-router-dom";
import EmailValidator from "../../../../helpers/emailvalidator";
import NumberInput from "../../../../components/numberinput";
import { Helmet } from "react-helmet";
import { useAppData } from "../../../../providers/AppDataProvider";

const CreateTeamMember = () => {
  const location = useLocation();
  const { strings } = useAppData();
  const itemData = location.state;
  const navigate = useNavigate();
  const inputRef = useRef();
  const [data, setData] = useState({
    name: itemData ? itemData.name : "",
    email: itemData ? itemData.email : "",
    office: itemData ? itemData.office : "",
    image: itemData ? itemData.image : "",
    order: itemData ? itemData.order : null,
    contact_page_order: itemData ? itemData.contact_page_order : null,
    designation: itemData ? itemData.designation : { en: "", sw: "" },
    phone_number: "",
    linkedin_url: itemData ? itemData.linkedin_url : "",
    education: [{ en: "", sw: "" }],
    experience: [{ en: "", sw: "" }],
    competence: [{ en: "", sw: "" }],
  });
  const [mobileNo, setMobileNo] = useState(
    itemData ? itemData.phone_number.slice(7) : ""
  );
  const [experArr, setExperArr] = useState(
    itemData ? itemData.experience : [{ en: "", sw: "" }]
  );
  const [eduArr, setEduArr] = useState(
    itemData ? itemData.education : [{ en: "", sw: "" }]
  );
  const [competeArr, setCompeteArr] = useState(
    itemData ? itemData.competence : [{ en: "", sw: "" }]
  );
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [emailErr, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState("");
  const [orderErr, setOrderError] = useState("");
  const [orderSuccess, setOrderSuccess] = useState("");
  const [contactOrderErr, setContactOrderError] = useState("");
  const [contactOrderSuccess, setContactOrderSuccess] = useState("");
  const [imageData, setImageData] = useState();
  const [successEmpty, setSuccessEmpty] = useState(false);
  //image upload function by validating file format
  const imageChange = (e) => {
    setSuccess();
    setError();
    if (e.target.files && e.target.files.length > 0) {
      var ext = e.target.files[0].name.match(/\.([^\.]+)$/)[1];
      switch (ext) {
        case "jpg":
        case "jpeg":
        case "png":
          setImageData(e.target.files[0]);
          break;
        default:
          alert("Not allowed");
          setImageData();
          inputRef.current.value = "";
      }
    }
  };
  // url validating function for linkedin
  const validateLinkedInUrl = (string) => {
    var res = string.match(
      /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    );
    return res !== null;
  };
  //function for adding list item in content
  const addListitem = (name) => {
    if (name === "edu") {
      setEduArr([...eduArr, { en: "", sw: "" }]);
    }
    if (name === "exp") {
      setExperArr([...experArr, { en: "", sw: "" }]);
    }
    if (name === "com") {
      setCompeteArr([...competeArr, { en: "", sw: "" }]);
    }
  };
  //function for removing list item from content
  const removeListItem = (index, name) => {
    if (name === "edu") {
      if (eduArr.length > 1) {
        const newarr = eduArr.filter((item, i) => i !== index);
        setEduArr(newarr);
      }
    }
    if (name === "exp") {
      if (experArr.length > 1) {
        const newarr = experArr.filter((item, i) => i !== index);
        setExperArr(newarr);
      }
    }
    if (name === "com") {
      if (competeArr.length > 1) {
        const newarr = competeArr.filter((item, i) => i !== index);
        setCompeteArr(newarr);
      }
    }
  };
  //function for handling list item on which section
  const listHandel = (e, index, name, lang) => {
    if (name === "edu") {
      const newArr = eduArr.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            en: lang === "en" ? e.target.value : item.en,
            sw: lang === "sw" ? e.target.value : item.sw,
          };
        } else {
          return item;
        }
      });
      setEduArr(newArr);
    }
    if (name === "exp") {
      const newArr = experArr.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            en: lang === "en" ? e.target.value : item.en,
            sw: lang === "sw" ? e.target.value : item.sw,
          };
        } else {
          return item;
        }
      });
      setExperArr(newArr);
    }
    if (name === "com") {
      const newArr = competeArr.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            en: lang === "en" ? e.target.value : item.en,
            sw: lang === "sw" ? e.target.value : item.sw,
          };
        } else {
          return item;
        }
      });
      setCompeteArr(newArr);
    }
  };
  //validation for inputs values
  function checkEmptyStrings(array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].en === "") {
        return true; // Found an empty string
      }
      if (array[i].sw === "") {
        return true; // Found an empty string
      }
    }
    return false; // No empty strings found
  }
  //validation for before calling api
  const onSubmit = () => {
    if (data.name === "") {
      setError("Name is Required");
      setSuccess();
    } else if (data.email === "") {
      setError("Email is Required");
      setSuccess();
    } else if (data.office === "") {
      setError("Office is Required");
      setSuccess();
    } else if (data.designation.en === "") {
      setError("English designation is Required");
      setSuccess();
    } else if (data.designation.sw === "") {
      setError("Swedish designation is Required");
      setSuccess();
    } else if (mobileNo === "") {
      setError("Phone Number is Required");
      setSuccess();
    } else if (data.linkedin_url === "") {
      setError("LinkedIn is Required");
      setSuccess();
    } else if (eduArr.length > 0 && checkEmptyStrings(eduArr)) {
      setError("Education value is Required");
      setSuccess();
    } else if (experArr.length > 0 && checkEmptyStrings(experArr)) {
      setError("Experiance value is Required");
      setSuccess();
    } else if (competeArr.length > 0 && checkEmptyStrings(competeArr)) {
      setError("Competence value is Required");
      setSuccess();
    } else if (data.image === "") {
      setError("Image is Required");
      setSuccess();
    } else if (!data.order) {
      setError("Order is Required");
      setSuccess();
    } else {
      if (itemData) {
        onUpdateApi();
      } else {
        onSubmitApi();
      }
    }
  };
  //api for creating new team member
  const onSubmitApi = () => {
    setError("");
    setSuccess("");
    setSuccessEmpty(false);
    try {
      createTeamMemberApi({
        ...data,
        phone_number: "+46 (0) " + mobileNo,
        education: eduArr,
        experience: experArr,
        competence: competeArr,
      }).then((res) => {
        if (res.data.type === "success") {
          setSuccess(res.data.message);
          setData({
            name: "",
            email: "",
            office: "",
            image: "",
            order: "",
            contact_page_order: "",
            designation: { en: "", sw: "" },
            phone_number: "",
            linkedin_url: "",
            education: [{ en: "", sw: "" }],
            experience: [{ en: "", sw: "" }],
            competence: [{ en: "", sw: "" }],
          });
          setMobileNo("");
          setEduArr([{ en: "", sw: "" }]);
          setExperArr([{ en: "", sw: "" }]);
          setCompeteArr([{ en: "", sw: "" }]);
          setSuccessEmpty(true);
          setImageData();
          setEmailSuccess();
          setContactOrderSuccess();
          setOrderSuccess();
          inputRef.current.value = "";
        } else {
          setError(res.data.message);
        }
      });
    } catch (error) {
      setError(error);
    }
  };
  //api for edited team member upload
  const onUpdateApi = () => {
    setError("");
    setSuccess("");
    try {
      updateTeamMemberApi({
        ...data,
        phone_number: "+46 (0) " + mobileNo,
        team_member_id: itemData._id,
        education: eduArr,
        experience: experArr,
        competence: competeArr,
      }).then((res) => {
        if (res.data.type === "success") {
          alert(res.data.message);
          navigate("/teammemberlist");
        } else {
          setError(res.data.message);
        }
      });
    } catch (error) {
      setError(error);
    }
  };
  return (
    <div className={styles.pageContainerStyle}>
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        <title>
          RosholmDell |{" "}
          {itemData ? strings.common.updateTeam : strings.common.createTeam}
        </title>
      </Helmet>
      <h1>{itemData ? "Update Team Member" : "Create Team Member"}</h1>
      <div className={styles.teamContainerStyle}>
        <div className={styles.gridContainerStyle}>
          <div className={styles.inputContainerStyle}>
            <h4>Name of Team Member</h4>
            <div className={styles.inputInsideContainerStyle}>
              <TextInput
                placeholder={"Name..."}
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value.trimStart() });
                  setSuccess();
                  setError();
                }}
              />
              {data.name.length > 1 && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.inputContainerStyle}>
            <h4>Email</h4>
            <div className={styles.inputInsideContainerStyle}>
              <TextInput
                placeholder={"Email..."}
                value={data.email}
                onChange={(e) => {
                  if (EmailValidator(e.target.value)) {
                    setEmailSuccess("Valid email");
                    setEmailError();
                  } else {
                    setEmailError("Not Valid Email");
                    setEmailSuccess();
                  }
                  setData({ ...data, email: e.target.value.trimStart() });
                  setSuccess();
                  setError();
                }}
              />
              {data.email.length > 1 && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
            {emailErr && <p className={styles.errorMessageStyle}>{emailErr}</p>}
            {emailSuccess && (
              <p className={styles.successMessageStyle}>{emailSuccess}</p>
            )}
          </div>
          <div className={styles.inputContainerStyle}>
            <h4>Office</h4>
            <div className={styles.inputInsideContainerStyle}>
              <TextInput
                placeholder={"Office..."}
                value={data.office}
                onChange={(e) => {
                  setData({ ...data, office: e.target.value.trimStart() });
                  setSuccess();
                  setError();
                }}
              />
              {data.office.length > 1 && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.inputContainerStyle}>
            <h4>Designation</h4>
            <div className={styles.inputInsideContainerStyle}>
              <div className={styles.designationContainerStyle}>
                <p>English</p>
                <TextInput
                  placeholder={"English Designation..."}
                  value={data.designation.en}
                  onChange={(e) => {
                    setData({
                      ...data,
                      designation: {
                        ...data.designation,
                        en: e.target.value.trimStart(),
                      },
                    });
                    setSuccess();
                    setError();
                  }}
                />
              </div>

              {data.designation.en.length > 1 && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
            <div className={styles.inputInsideContainerStyle}>
              <div className={styles.designationContainerStyle}>
                <p>Swedish</p>
                <TextInput
                  placeholder={"Swedish Designation..."}
                  value={data.designation.sw}
                  onChange={(e) => {
                    setData({
                      ...data,
                      designation: {
                        ...data.designation,
                        sw: e.target.value.trimStart(),
                      },
                    });
                    setSuccess();
                    setError();
                  }}
                />
              </div>
              {data.designation.sw.length > 1 && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.inputContainerStyle}>
            <h4>Phone Number</h4>
            <div className={styles.inputInsideContainerStyle}>
              <NumberInput
                placeholder={"Phone Number..."}
                value={mobileNo}
                onChange={(value) => {
                  setMobileNo(value.target.value);
                  setSuccess();
                  setError();
                }}
              />
              {data.phone_number.length > 1 && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.inputContainerStyle}>
            <h4>LinkedIn url</h4>
            <div className={styles.inputInsideContainerStyle}>
              <TextInput
                placeholder={"Url..."}
                value={data.linkedin_url}
                onChange={(e) => {
                  if (validateLinkedInUrl(e.target.value)) {
                    setSuccess("Valid url");
                    setError();
                  } else {
                    setError("Not a Valid url");
                    setSuccess();
                  }
                  setData({
                    ...data,
                    linkedin_url: e.target.value.trimStart(),
                  });
                }}
              />
              {data.linkedin_url.length > 1 && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.gridContainerStyle}>
          <div className={styles.inputContainerStyle}>
            <h4>Education</h4>
            {eduArr.map((item, index) => {
              return (
                <div key={index} className={styles.inputContainerStyle}>
                  <div className={styles.listStyle}>
                    <div className={styles.inputInsideLangContainerStyle}>
                      <div className={styles.labelContainerStyle}>
                        <p>English</p>
                        <div className={styles.langContainerStyle}>
                          <TextInput
                            placeholder={"list..."}
                            value={item.en}
                            onChange={(e) => {
                              listHandel(e, index, "edu", "en");
                              setSuccess();
                              setError();
                            }}
                          />
                          {item.en.length > 1 && (
                            <div className={styles.closeIconStyle}>
                              <CheckIconSvg color={"#007f00"} />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.labelContainerStyle}>
                        <p>Swedish</p>
                        <div className={styles.langContainerStyle}>
                          <TextInput
                            placeholder={"list..."}
                            value={item.sw}
                            onChange={(e) => {
                              listHandel(e, index, "edu", "sw");
                              setSuccess();
                              setError();
                            }}
                          />
                          {item.sw.length > 1 && (
                            <div className={styles.closeIconStyle}>
                              <CheckIconSvg color={"#007f00"} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {eduArr.length > 1 && (
                      <div
                        className={styles.closeIconStyle}
                        onClick={() => removeListItem(index, "edu")}
                      >
                        <CloseIconSvg color={"#000000"} />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            <Button
              btnStyle={styles.buttonStyle}
              buttonName={"list+1"}
              onClick={() => addListitem("edu")}
            />
          </div>
          <div className={styles.inputContainerStyle}>
            <h4>Experiance</h4>
            {experArr.map((item, index) => {
              return (
                <div key={index} className={styles.inputContainerStyle}>
                  <div className={styles.listStyle}>
                    <div className={styles.inputInsideLangContainerStyle}>
                      <div className={styles.labelContainerStyle}>
                        <p>English</p>
                        <div className={styles.langContainerStyle}>
                          <TextInput
                            placeholder={"list..."}
                            value={item.en}
                            onChange={(e) => {
                              listHandel(e, index, "exp", "en");
                              setSuccess();
                              setError();
                            }}
                          />

                          {item.en.length > 1 && (
                            <div className={styles.closeIconStyle}>
                              <CheckIconSvg color={"#007f00"} />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.labelContainerStyle}>
                        <p>Swedish</p>
                        <div className={styles.langContainerStyle}>
                          <TextInput
                            placeholder={"list..."}
                            value={item.sw}
                            onChange={(e) => {
                              listHandel(e, index, "exp", "sw");
                              setSuccess();
                              setError();
                            }}
                          />

                          {item.sw.length > 1 && (
                            <div className={styles.closeIconStyle}>
                              <CheckIconSvg color={"#007f00"} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {experArr.length > 1 && (
                      <div
                        className={styles.closeIconStyle}
                        onClick={() => removeListItem(index, "exp")}
                      >
                        <CloseIconSvg color={"#000000"} />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            <Button
              btnStyle={styles.buttonStyle}
              buttonName={"list+1"}
              onClick={() => addListitem("exp")}
            />
          </div>
          <div className={styles.inputContainerStyle}>
            <h4>Competence</h4>
            {competeArr.map((item, index) => {
              return (
                <div key={index} className={styles.inputContainerStyle}>
                  <div className={styles.listStyle}>
                    <div className={styles.inputInsideLangContainerStyle}>
                      <div className={styles.labelContainerStyle}>
                        <p>English</p>
                        <div className={styles.langContainerStyle}>
                          <TextInput
                            placeholder={"list..."}
                            value={item.en}
                            onChange={(e) => {
                              listHandel(e, index, "com", "en");
                              setSuccess();
                              setError();
                            }}
                          />

                          {item.en.length > 1 && (
                            <div className={styles.closeIconStyle}>
                              <CheckIconSvg color={"#007f00"} />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.labelContainerStyle}>
                        <p>Swedish</p>
                        <div className={styles.langContainerStyle}>
                          <TextInput
                            placeholder={"list..."}
                            value={item.sw}
                            onChange={(e) => {
                              listHandel(e, index, "com", "sw");
                              setSuccess();
                              setError();
                            }}
                          />

                          {item.sw.length > 1 && (
                            <div className={styles.closeIconStyle}>
                              <CheckIconSvg color={"#007f00"} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {competeArr.length > 1 && (
                      <div
                        className={styles.closeIconStyle}
                        onClick={() => removeListItem(index, "com")}
                      >
                        <CloseIconSvg color={"#000000"} />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            <Button
              btnStyle={styles.buttonStyle}
              buttonName={"list+1"}
              onClick={() => addListitem("com")}
            />
          </div>
        </div>
        <div className={styles.gridContainerStyle}>
          <div className={styles.inputContainerStyle}>
            <h4>Order</h4>
            <div className={styles.inputInsideContainerStyle}>
              <TextInput
                placeholder={"Order..."}
                value={data.order}
                min={1}
                type={"number"}
                onChange={(e) => {
                  if (e.target.value < 1) {
                    setOrderError("Not allowed Negative values");
                    setOrderSuccess();
                  } else {
                    setOrderSuccess("Valid order");
                    setOrderError();
                  }
                  setData({ ...data, order: e.target.value.trimStart() });
                  setSuccess();
                  setError();
                }}
              />

              {data.order && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
            {orderErr && <p className={styles.errorMessageStyle}>{orderErr}</p>}
            {orderSuccess && (
              <p className={styles.successMessageStyle}>{orderSuccess}</p>
            )}
          </div>
          <div className={styles.inputContainerStyle}>
            <h4>Contact Page Order</h4>
            <div className={styles.inputInsideContainerStyle}>
              <TextInput
                placeholder={"Contact Page Order..."}
                value={data.contact_page_order}
                min={1}
                type={"number"}
                onChange={(e) => {
                  if (e.target.value < 1) {
                    setContactOrderError("Not allowed Negative values");
                    setContactOrderSuccess();
                  } else {
                    setContactOrderSuccess("Valid Contact order");
                    setContactOrderError();
                  }
                  setData({
                    ...data,
                    contact_page_order: e.target.value.trimStart(),
                  });
                  setSuccess();
                  setError();
                }}
              />

              {data.contact_page_order && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
            {contactOrderErr && (
              <p className={styles.errorMessageStyle}>{contactOrderErr}</p>
            )}
            {contactOrderSuccess && (
              <p className={styles.successMessageStyle}>
                {contactOrderSuccess}
              </p>
            )}
          </div>
          <div className={styles.inputContainerStyle}>
            <div className={styles.checkContainerStyle}>
              <h4>Image</h4>
              {data.image !== "" && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
            <ImageUpload
              inputRef={inputRef}
              imageChange={(e) => imageChange(e)}
              selectedImage={data.image}
              imageData={imageData}
              successEmpty={successEmpty}
              setImageUrl={(url) => {
                setData({ ...data, image: url });
                setSuccess();
                setError();
              }}
              imageContainerStyle={styles.imageContainerStyle}
              imageWrapperStyle={styles.imageWrapperStyle}
            />
          </div>
        </div>
      </div>
      {error && <p className={styles.errorMessageStyle}>{error}</p>}
      {success && <p className={styles.successMessageStyle}>{success}</p>}
      <Button
        buttonName={itemData ? "Update" : "Create"}
        btnStyle={styles.buttonStyle}
        onClick={() => onSubmit()}
      />
    </div>
  );
};

export default CreateTeamMember;
