import React from "react";
import styles from "./styles.module.css";

const findLinksAndEmails = (text) => {
  console.log(text);
  const linkRegex = /(?:(?:https?|ftp):\/\/|www\.)[^\s/$.?#].[^\s]*/g; // Regex to match URLs
  const emailRegex = /\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/g; // Regex to match email addresses
  const links = text.match(linkRegex) || [];
  const emails = text.match(emailRegex) || [];
  // Replace URLs with clickable links
  const textWithLinks = links.reduce(
    (acc, link) =>
      acc.replace(
        link,
        `<a style="color:#D16C1D" href="${link}" target="_blank">${link}</a>`
      ),
    text
  );
  // Replace email addresses with clickable email links
  const textWithLinksAndEmails = emails.reduce(
    (acc, email) =>
      acc.replace(
        email,
        `<a  style="color:#D16C1D"  href="mailto:${email}">${email}</a>`
      ),
    textWithLinks
  );
  return textWithLinksAndEmails;
};

export default findLinksAndEmails;
