import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.css";
import TextInput from "../../../../components/textInput";
import Button from "../../../../components/button";
import ContentComponent from "../../../../components/contentcomponent";
import {
  createPostApi,
  updatePostsApi,
  getPostDetailsByIdApi,
} from "../../../../networking/api/posts";
import { useLocation } from "react-router-dom";
import {
  CheckIconSvg,
  CloseIconSvg,
} from "../../../../components/svgcomponents";
import ImageUpload from "../../../../components/imageuploadcomponent";
import { fileUploadApi } from "../../../../networking/api/imageupload";
import ButtonLoader from "../../../../components/buttonloader";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAppData } from "../../../../providers/AppDataProvider";
import moment from "moment/moment";
const CreateNewsAndInsights = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { strings } = useAppData();

  const data = location.state;
  const [itemData, setItemData] = useState();
  const [contentArr, setContentArr] = useState(
    itemData ? itemData.content : []
  );
  const [blog, setBlog] = useState({
    title: itemData ? itemData.title : { en: "", sw: "" },
    title_size: itemData ? itemData.title_size : "",
    image: itemData ? itemData.image : "",
    featured: itemData ? itemData.featured : false,
    content: [],
    category: itemData ? itemData.category : "",
    pdf_url: itemData ? itemData.pdf_url : "",
    created_at: itemData ? itemData?.created_at : "",
  });
  const [pdf, setPdf] = useState();
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [uploadErr, setUploadErr] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState("");
  const inputRef = useRef();
  const fileRef = useRef();
  const [successEmpty, setSuccessEmpty] = useState(false);
  const [imageData, setImageData] = useState();
  const [loader, setLoader] = useState(false);
  //api for getting news and insights data for editing
  useEffect(() => {
    if (data && data._id) {
      try {
        getPostDetailsByIdApi(data._id).then((res) => {
          if (res.data.type === "success") {
            setItemData(res.data.data);
          } else {
            console.log(res.data.message);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [data]);
  useEffect(() => {
    setBlog({
      title: itemData ? itemData.title : { en: "", sw: "" },
      title_size: itemData ? itemData.title_size : "",
      image: itemData ? itemData.image : "",
      featured: itemData ? itemData.featured : false,
      content: [],
      category: itemData ? itemData.category : "",
      pdf_url: itemData ? itemData.pdf_url : "",
      created_at: itemData ? itemData?.created_at : "",
    });
    setContentArr(itemData ? itemData.content : []);
  }, [itemData]);
  // image change function for editing news and insights
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      var ext = e.target.files[0].name.match(/\.([^\.]+)$/)[1];
      switch (ext) {
        case "jpg":
        case "jpeg":
        case "png":
          setImageData(e.target.files[0]);
          break;
        default:
          alert("Not allowed");
          setImageData();
          inputRef.current.value = "";
      }
    }
  };
  const isValueEmpty = (myArray) => {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].type === "paragraph") {
        if (myArray[i].value.en === "") {
          return true;
        }
        if (myArray[i].value.sw === "") {
          return true;
        }
      }
      if (myArray[i].type === "image") {
        if (myArray[i].image_link === "") {
          return true;
        }
      }
      if (myArray[i].type === "link") {
        if (myArray[i].link.title.en === "") {
          return true;
        }
        if (myArray[i].link.title.sw === "") {
          return true;
        }
        if (myArray[i].link.url === "") {
          return true;
        }
      }
      if (myArray[i].type === "list") {
        for (let j = 0; j < myArray[i].items.length; j++) {
          if (myArray[i].items[j].en === "") {
            return true;
          }
          if (myArray[i].items[j].sw === "") {
            return true;
          }
        }
      }
    }
    return false;
  };
  const isValueEmptyErr = (myArray) => {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].type === "paragraph") {
        if (myArray[i].value.en === "") {
          return "paragraph english value is required";
        }
        if (myArray[i].value.sw === "") {
          return "paragraph swedish value is required";
        }
      }
      if (myArray[i].type === "image") {
        if (myArray[i].image_link === "") {
          return "content image is required";
        }
      }
      if (myArray[i].type === "link") {
        if (myArray[i].link.title.en === "") {
          return "link title english value is required";
        }
        if (myArray[i].link.title.sw === "") {
          return "link title swedish value is required";
        }
        if (myArray[i].link.url === "") {
          return "link title url value is required";
        }
      }
      if (myArray[i].type === "list") {
        for (let j = 0; j < myArray[i].items.length; j++) {
          if (myArray[i].items[j].en === "") {
            return "list item english value is required";
          }
          if (myArray[i].items[j].sw === "") {
            return "list item swedish value is required";
          }
        }
      }
    }
    return false;
  };
  const onSubmit = () => {
    if (blog.title.en === "") {
      setErr("English title is Required");
    } else if (blog.title.sw === "") {
      setErr("Swedish title is Required");
    } else if (blog.image === "") {
      setErr("Image is Required");
    } else if (contentArr.length === 0) {
      setErr("Content is Required");
    } else if (contentArr.length > 0 && isValueEmpty(contentArr)) {
      setErr(isValueEmptyErr(contentArr));
    } else if (blog.category === "") {
      setErr("Category is Required");
    } else {
      if (itemData) {
        onUpdate();
      } else {
        submitPostApi();
      }
    }
  };
  //api for creating news and insights
  const submitPostApi = () => {
    setSuccessEmpty(false);
    setSuccess();
    setErr();
    try {
      createPostApi({
        ...blog,
        content: contentArr,
      }).then((res) => {
        if (res.data.type === "success") {
          setBlog({
            title: { en: "", sw: "" },
            title_size: "",
            image: "",
            content: [],
            category: "",
            pdf_url: "",
            created_at: "",
          });
          setContentArr([]);
          setSuccess(res.data.message);
          inputRef.current.value = "";
          setSuccessEmpty(true);
          setImageData();
          setPdf();
          setUploadSuccess();
          setUploadErr();
          fileRef.current.value = "";
        } else {
          setErr(res.data.message);
        }
      });
    } catch (error) {
      setErr(error);
    }
  };
  //api for updating edited news and insights
  const onUpdate = () => {
    setErr("");
    setSuccess("");
    try {
      updatePostsApi({
        ...blog,
        post_id: itemData._id,
        content: contentArr,
      }).then((res) => {
        if (res.data.type === "success") {
          setSuccess(res.data.message);
          alert(res.data.message);
          navigate("/news&insightslist");
        } else {
          setErr(res.data.message);
        }
      });
    } catch (error) {
      setErr(error);
    }
  };
  //api for uploading pdf into aws
  const uploadPdf = () => {
    setLoader(true);
    const data = new FormData();
    data.append("file", pdf);
    try {
      fileUploadApi(data).then((response) => {
        if (response.data.type === "success") {
          setBlog({ ...blog, pdf_url: response.data.data });
          setUploadSuccess("File uploaded successfully");
          setLoader(false);
        } else {
          setUploadErr(response.data.message);
        }
      });
    } catch (error) {
      setUploadErr(error);
    }
  };
  return (
    <div className={styles.mainContainerStyle}>
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        <title>
          RosholmDell |
          {itemData
            ? strings.common.updateNewsPost
            : strings.common.createNewsPost}
        </title>
      </Helmet>
      <h1>
        {itemData
          ? "Update News & Insights Post"
          : "Create News & Insights Post"}
      </h1>
      <div className={styles.insideContainerStyle}>
        <div className={styles.inputContainerStyle}>
          <h4>Title</h4>
          <div className={styles.titleContainerStyle}>
            <p>English</p>
            <div className={styles.inputInsideContainerStyle}>
              <TextInput
                placeholder={"English title..."}
                value={blog.title.en}
                onChange={(e) => {
                  setBlog({
                    ...blog,
                    title: { ...blog.title, en: e.target.value.trimStart() },
                  });
                  setErr();
                  setSuccess();
                }}
              />
              {blog.title.en.length > 1 && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.titleContainerStyle}>
            <p>Swedish</p>
            <div className={styles.inputInsideContainerStyle}>
              <TextInput
                placeholder={"Swedish title..."}
                value={blog.title.sw}
                onChange={(e) => {
                  setBlog({
                    ...blog,
                    title: { ...blog.title, sw: e.target.value.trimStart() },
                  });
                  setErr();
                  setSuccess();
                }}
              />
              {blog.title.sw.length > 1 && (
                <div className={styles.closeIconStyle}>
                  <CheckIconSvg color={"#007f00"} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.inputContainerStyle}>
          <h4>Title Font size (in %)</h4>
          <div className={styles.inputInsideContainerStyle}>
            <TextInput
              placeholder={"Percentage.."}
              value={blog.title_size}
              onChange={(e) => {
                setBlog({ ...blog, title_size: e.target.value.trimStart() });
                setErr();
                setSuccess();
              }}
            />
            {blog.title.length > 1 && (
              <div className={styles.closeIconStyle}>
                <CheckIconSvg color={"#007f00"} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.inputContainerStyle}>
          <div className={styles.checkContainerStyle}>
            <h4>Image</h4>
            {blog.image !== "" && (
              <div className={styles.closeIconStyle}>
                <CheckIconSvg color={"#007f00"} />
              </div>
            )}
          </div>
          <ImageUpload
            inputRef={inputRef}
            imageChange={(e) => imageChange(e)}
            selectedImage={blog.image}
            imageData={imageData}
            successEmpty={successEmpty}
            setImageUrl={(url) => setBlog({ ...blog, image: url })}
          />
        </div>
        <div className={styles.inputContainerStyle}>
          <h4>Content</h4>
          <ContentComponent Array={contentArr} SetArray={setContentArr} />
        </div>
        <div className={styles.inputContainerStyle}>
          <h4>Categeories</h4>

          <div className={styles.inputContainerStyle}>
            <div className={styles.listStyle}>
              <div className={styles.inputInsideContainerStyle}>
                <select
                  value={blog.category}
                  onChange={(e) => {
                    setBlog({ ...blog, category: e.target.value });
                    setErr();
                    setSuccess();
                  }}
                >
                  <option value="">Select Categeory</option>
                  <option value="News">News</option>
                  <option value="Insights">Insights</option>
                </select>
                {blog.category !== "" && (
                  <div className={styles.closeIconStyle}>
                    <CheckIconSvg color={"#007f00"} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.inputContainerStyle}>
          <h4>Feature it</h4>
          <div className={styles.inputInsideContainerStyle}>
            <TextInput
              inputStyle={styles.inputStyle}
              type="checkbox"
              checked={blog.featured}
              value={blog.featured}
              onChange={(e) => {
                setBlog({ ...blog, featured: !blog.featured });
                setErr();
                setSuccess();
              }}
            />
            {blog.featured === true && (
              <div className={styles.closeIconStyle}>
                <CheckIconSvg color={"#007f00"} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.inputContainerStyle}>
          <h4>Created At</h4>
          <div className={styles.inputInsideContainerStyle}>
            <TextInput
              type="date"
              value={moment(blog.created_at).format("YYYY-MM-DD")}
              onChange={(e) => {
                setBlog({ ...blog, created_at: e.target.value });
                setErr();
                setSuccess();
              }}
            />

            {blog.created_at !== "" && (
              <div className={styles.closeIconStyle}>
                <CheckIconSvg color={"#007f00"} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.inputContainerStyle}>
          <div className={styles.checkContainerStyle}>
            <h4>Pdf file</h4>
          </div>
          <div className={styles.pdfContainerStyle}>
            {blog?.pdf_url && <p>upload new pdf</p>}
            <input
              ref={fileRef}
              type="file"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  var ext = e.target.files[0].name.match(/\.([^\.]+)$/)[1];
                  switch (ext) {
                    case "pdf":
                      setPdf(e.target.files[0]);
                      break;
                    default:
                      alert("Not allowed");
                      setPdf();
                      fileRef.current.value = "";
                  }
                }

                setUploadErr();
                setUploadSuccess();
              }}
            />
            {blog?.pdf_url && (
              <a href={blog?.pdf_url} target="_blank">
                view pdf
              </a>
            )}
            {blog?.pdf_url && (
              <div
                className={styles.closeIconStyle}
                onClick={() => {
                  setBlog({ ...blog, pdf_url: "" });
                  fileRef.current.value = "";
                  setUploadSuccess();
                  setUploadErr();
                  setPdf();
                }}
              >
                <CloseIconSvg color={"#000000"} />
              </div>
            )}
          </div>
          {uploadErr && <p className={styles.errorTextStyle}>{uploadErr}</p>}
          {uploadSuccess && (
            <p className={styles.successTextStyle}>{uploadSuccess}</p>
          )}
          {pdf && (
            <Button
              buttonName={loader ? <ButtonLoader /> : "file upload"}
              btnStyle={styles.buttonStyle}
              onClick={() => {
                uploadPdf();
              }}
            />
          )}
        </div>
        {err && <p className={styles.errorTextStyle}>{err}</p>}
        {success && <p className={styles.successTextStyle}>{success}</p>}
        <Button
          buttonName={itemData ? "Update" : "Create"}
          btnStyle={styles.buttonStyle}
          onClick={() => {
            onSubmit();
          }}
        />
      </div>
    </div>
  );
};

export default CreateNewsAndInsights;
