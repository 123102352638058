import React, { useEffect, useState } from "react";
import commonStyles from "../../components/css/common.module.css";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const CookieNoticePage = () => {
  const { strings, modalOpen, focus, setFocus } = useAppData();
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  const Data1 = [
    strings.cookieNoticePage.subtitletwoDesc.three[1],
    strings.cookieNoticePage.subtitletwoDesc.three[2],
  ];
  const Data2 = [
    strings.cookieNoticePage.subtitlesevenDesc.four[1],
    strings.cookieNoticePage.subtitlesevenDesc.four[2],
    strings.cookieNoticePage.subtitlesevenDesc.four[3],
    strings.cookieNoticePage.subtitlesevenDesc.four[4],
    strings.cookieNoticePage.subtitlesevenDesc.four[5],
  ];
  return (
    <div
      className={
        focus
          ? styles.whistlePolicyPageFocusContainerStyle
          : styles.whistlePolicyPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Useful leagal services" />
        <meta name="keywords" content="Useful leagal services" />
        <title>RosholmDell | {strings.common.cookieNotice}</title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.pageInsideContainerStyle}>
          <div className={styles.headerContainerStyle}>
            <h2 className={styles.headerTextStyle}>
              {strings.cookieNoticePage.title}
            </h2>

            <p className={styles.contentTextStyle}>
              <span
                dangerouslySetInnerHTML={{
                  __html: strings.cookieNoticePage.titledesc,
                }}
              ></span>
              &nbsp;
              <a
                href="https://www.rosholmdell.se/"
                target="_blank"
                className={styles.contentBlueTextstyle}
              >
                {strings.cookieNoticePage.titleLink}
              </a>
              &nbsp;
              <span
                dangerouslySetInnerHTML={{
                  __html: strings.cookieNoticePage.titledesc2,
                }}
              ></span>
            </p>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.cookieNoticePage.subtitleone}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitleoneDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitleoneDesc.two}
              </p>
              <p
                className={styles.contentTextStyle}
                dangerouslySetInnerHTML={{
                  __html: strings.cookieNoticePage.subtitleoneDesc.three,
                }}
              />
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitleoneDesc.four}
              </p>
              <p
                className={styles.contentTextStyle}
                dangerouslySetInnerHTML={{
                  __html: strings.cookieNoticePage.subtitleoneDesc?.five,
                }}
              />
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.cookieNoticePage.subtitletwo}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitletwoDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitletwoDesc.two}
              </p>
              <ul>
                {Data1.map((item, index) => {
                  return (
                    <li key={index} className={styles.contentTextStyle}>
                      {item}
                    </li>
                  );
                })}
              </ul>
              <p
                className={styles.contentTextStyle}
                dangerouslySetInnerHTML={{
                  __html: strings.cookieNoticePage.subtitletwoDesc?.four,
                }}
              />
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.cookieNoticePage.subtitlethree}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlethreeDesc.one}
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.cookieNoticePage.subtitlefour}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlefourDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlefourDesc.two}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlefourDesc.three}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlefourDesc.four}
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.cookieNoticePage.subtitlefive}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlefiveDesc.one}
              </p>

              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlefiveDesc.two}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.cookieNoticePage.subtitlefiveDesc.three.title}
                </span>
                {strings.cookieNoticePage.subtitlefiveDesc.three.desc}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.cookieNoticePage.subtitlefiveDesc.four.title}
                </span>
                {strings.cookieNoticePage.subtitlefiveDesc.four.desc}
              </p>
              <p className={styles.contentTextStyle}>
                <span className={styles.contentOrangeTextstyle}>
                  {strings.cookieNoticePage.subtitlefiveDesc.five.title}
                </span>
                {strings.cookieNoticePage.subtitlefiveDesc.five.desc}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.cookieNoticePage?.subtitleTen}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage?.subtitleTenDesc.one}
              </p>
            </div>
          </div>
          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.cookieNoticePage.subtitlesix}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlesixDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlesixDesc.two}
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.cookieNoticePage.subtitleseven}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlesevenDesc.one}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlesevenDesc.two}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlesevenDesc.three}
              </p>
              <ul>
                {Data2.map((item, index) => {
                  return (
                    <li key={index} className={styles.contentTextStyle}>
                      {item}
                    </li>
                  );
                })}
              </ul>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlesevenDesc.five}
              </p>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlesevenDesc.six}
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.cookieNoticePage.subtitleeight}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitleeightDesc.one}
              </p>
            </div>
          </div>

          <div className={styles.itemContainerStyle}>
            <p className={styles.subTitleTextStyle}>
              {strings.cookieNoticePage.subtitlenine}
            </p>
            <div className={styles.contentContainerStyle}>
              <p className={styles.contentTextStyle}>
                {strings.cookieNoticePage.subtitlenineDesc.one}
                &nbsp;
                <a
                  href="mailto:info@rdlaw.se"
                  target="_blank"
                  className={styles.contentBlueTextstyle}
                >
                  {strings.cookieNoticePage.subtitlenineDesc.link}
                </a>
              </p>
            </div>
          </div>
        </div>
        <Footer blueFooter={true} />
      </div>
    </div>
  );
};

export default CookieNoticePage;
